/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable plugin/no-unsupported-browser-features */
/* stylelint-enable plugin/no-unsupported-browser-features */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_link_1kosq_2uf8g_99:not(#\9):after {
  display: none;
}

.awsui_breadcrumb_1kosq_2uf8g_103:not(#\9) {
  display: flex;
}
.awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_icon_1kosq_2uf8g_106:not(#\9) {
  margin-block: 0;
  margin-inline: var(--space-xs-xf5ch3, 8px);
  color: var(--color-text-breadcrumb-icon-egad48, #687078);
}
.awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9) {
  color: var(--color-text-link-default-hop3gv, #0073bb);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-0rwzu1, 165ms);
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
}
@media (prefers-reduced-motion: reduce) {
  .awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9), .awsui-mode-entering .awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-8s5z9d, #0a4a74);
}
.awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9):focus {
  outline: none;
}
.awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9):active {
  color: var(--color-text-link-hover-8s5z9d, #0a4a74);
}
.awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9):active, .awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9):focus, .awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
body[data-awsui-focus-visible=true] .awsui_breadcrumb_1kosq_2uf8g_103 > .awsui_anchor_1kosq_2uf8g_111:not(#\9):focus {
  outline: thin dotted;
  outline: var(--border-link-focus-ring-outline-gygm43, 5px auto Highlight);
  outline-offset: 2px;
  outline-color: var(--color-border-item-focused-v8nq2m, #0073bb);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-a78bgq, 2px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-a78bgq, 2px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-a78bgq, 2px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-a78bgq, 2px);
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-efbp5h, 0px) var(--color-border-item-focused-v8nq2m, #0073bb);
}
.awsui_breadcrumb_1kosq_2uf8g_103.awsui_last_1kosq_2uf8g_157 > .awsui_icon_1kosq_2uf8g_106:not(#\9) {
  display: none;
}
.awsui_breadcrumb_1kosq_2uf8g_103.awsui_last_1kosq_2uf8g_157 > .awsui_anchor_1kosq_2uf8g_111:not(#\9) {
  color: var(--color-text-breadcrumb-current-pm5uhh, #687078);
  font-weight: 700;
  text-decoration: none;
  cursor: default;
}

.awsui_compressed_1kosq_2uf8g_167:not(#\9) {
  min-inline-size: 0;
  overflow: hidden;
}
.awsui_compressed_1kosq_2uf8g_167 > .awsui_text_1kosq_2uf8g_171:not(#\9) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.awsui_virtual-item_1kosq_2uf8g_178:not(#\9) {
  position: absolute !important;
  inset-block-start: -9999px !important;
  inset-inline-start: -9999px !important;
  visibility: hidden;
}